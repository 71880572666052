// step 1
.location {
  position: relative;
  height: calc(100vh - 175px);
  background-position: center;
  background-repeat: no-repeat;
  @include respond-to(desktop) {
    height: calc(100vh - 110px);
  }

  .locationMask {
    background: rgba(0, 0, 0, 0.5);
    height: inherit;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    flex-direction: column;

    .title {
      font-size: 40px;
      line-height: 45px;
      text-align: center;
      top: calc(50% - 170px);
      font-weight: 300;
      color: #fff;
      position: absolute;

      @include respond-to(desktop) {
        font-size: 20px;
        line-height: 25px;
        top: calc(50% - 95px);
      }
      span {
        font-weight: 700;
        font-size: 60px;
        line-height: 65px;
        @include respond-to(desktop) {
          font-size: 25px;
          line-height: 30px;
        }
      }
    }
    .locationInner {
      position: relative;
      color: #fff;

      .subTitle {
        text-align: left;
        font-size: 14px;
        @include respond-to(desktop) {
          text-align: center;
          font-size: 11px;
        }
      }
      .searchInfo {
        position: relative;
        display: flex;
        img {
          position: absolute;
          max-width: 30px;
          left: 0;
          top: calc(50% - 15px);
          left: 5px;
        }
        input {
          min-width: 500px;
          max-width: 500px;
          padding-left: 45px;
          @include respond-to(desktop) {
            max-width: 300px;
            min-width: 300px;
          }
          @include respond-to(phone) {
            max-width: 300px;
            min-width: 0;
          }

          &:focus,
          &:active,
          &:hover {
            outline: none;
          }
        }
        .searchBtn {
          padding: 10px 30px;
          background: #2c8a2c;
          color: #fff;
          margin-left: 3px;
          text-transform: capitalize;
        }
      }

      .predictions {
        min-width: 500px;
        max-width: 500px;
        position: absolute;
        top: 65px;
        max-height: 150px;
        height: auto;
        overflow: auto;
        background: #fff;
        color: #000;
        &.active {
          padding: 0 5px;
        }

        @include respond-to(desktop) {
          max-width: 300px;
          min-width: 300px;
        }
        @include respond-to(phone) {
          max-width: 330px;
          min-width: 0;
        }

        .prediction {
          padding: 5px 10px;
          margin: 5px 0;
          cursor: pointer;
        }
      }
    }
  }
  &.small {
    height: auto;
    background-image: none;
    background-color: #fff;
    position: absolute;
    top: 85px;
    z-index: 999;
    left: 10px;
    .locationMask {
      background-color: inherit;
      padding: 6px;
      .title,
      .subTitle {
        display: none;
      }

      .searchInfo {
        img {
          left: 0;
          padding: 5px;
        }
        svg {
          background: #2c8a2c;
          padding: 5px;
          margin: 0 5px;
        }
        input {
          padding-left: 35px;
        }
      }
      .predictions {
        top: 37px;
        left: -6px;
        min-width: 552px !important;
        max-width: 552px !important;
        @include respond-to(desktop) {
          min-width: 245px !important;
          max-width: 245px !important;
        }
      }
    }
  }
}
