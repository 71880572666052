// menu
.backendMenuToggleBtn {
  position: absolute;
  left: 0;
  top: 75px;
  color: #fff;
  z-index: 1;
  .active {
    color: #000;
  }
  @include respond-to(desktop) {
    display: none;
  }
}
.wrappper_container {
  position: relative;
  display: flex;
  @include respond-to(desktop) {
    flex-direction: column;
  }
  .wrapper_side_right {
    position: relative;
    background: #dfdfdf;
    height: calc(100vh);
    padding: 15px;
    width: 80%;
    @include respond-to(desktop) {
      height: auto;
      padding: 5px;
      width: 100%;
    }
    .wrapper_side_right_inner {
      position: relative;
      background: #ffff;
      height: calc(100vh - 30px);
      overflow-y: auto;
      @include respond-to(desktop) {
        height: auto;
      }
    }
  }

  .wrapper_side_left {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: #000;
    color: #fff;
    width: 20%;
    @include respond-to(desktop) {
      height: auto;
      width: 100%;
    }
    .header {
      position: relative;
      @include respond-to(desktop) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .menuBtn {
        display: none;
        @include respond-to(desktop) {
          display: block;
          margin-right: 20px;
          font-size: 40px;
        }
      }
      .logo_info {
        margin: 20px;
        max-width: 250px;
        max-height: 55px;
        @include respond-to(desktop) {
          max-width: 250px;
        }
      }
    }

    .menuList {
      position: relative;
      overflow-y: auto;
      @include respond-to(desktop) {
        display: none;
        &.active {
          display: block;
        }
      }
      .menuListItem {
        position: relative;
        padding: 10px 20px;
        cursor: pointer;
        text-transform: capitalize;
        &.active {
          background-color: #9abf0f;
        }
      }
    }
    .copyRight {
      position: absolute;
      bottom: 10px;
      width: 100%;
      justify-content: center;
      display: flex;
      @include respond-to(desktop) {
        display: none;
      }
    }
  }
}

// login
.loginContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  max-width: 360px;
  left: calc(50% - 160px);
  .infoBtn {
    display: flex;
    justify-content: flex-end;
  }
}

// loading

.loadingIcon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 40px;
}

//
.leadForm {
  padding: 20px;
  .accordion-body,
  .leadFormInner {
    background: #efefef;
  }
  .leadFormInner {
    padding: 20px;
  }
}

//
.QuoteForm {
  padding: 20px;
  .accordion-body,
  .leadFormInner {
    background: #efefef;
  }

  .btns {
    .btn {
      cursor: pointer !important;
    }
  }

  .frontFormOuter {
    top: 0;
    height: 100vh;
  }

  .footer {
    display: none !important;
  }

  #customMap,
  .custom-container,
  .location {
    height: 100vh;
    overflow-y: auto;
    &.small {
      top: 10px;
    }
  }

  .leadFormInner {
    padding: 20px;
  }
  .toggle_btn {
    top: 10px !important;
  }
  .rotationBtn {
    top: 36px;
    &.large {
      top: 95px;
    }
  }
}

.custom_legend {
  position: relative;
  display: flex;
  align-items: center;
  @include respond-to(desktop) {
    flex-direction: column;
  }
  .custom_legendItem {
    padding-left: 30px;
    position: relative;
    padding-right: 10px;
    display: flex;
    align-items: center;
    &::before {
      content: " ";
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      left: 0;
    }
    &.yash_clr {
      &::before {
        background-color: #dfdfdf;
      }
    }
    &.orange_clr {
      &::before {
        background-color: orange;
      }
    }
    &.orange_green_clr {
      padding-left: 40px;
      &::before {
        background-color: orange;
      }
      &::after {
        content: " ";
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        left: 10px;
        z-index: 1;
        background: green;
      }
    }

    &.green_clr {
      &::before {
        background-color: green;
      }
    }
  }
}

//
.calenderForm {
  position: relative;
  padding: 20px;
  height: calc(100vh - 30px);
  .selectOptions {
    position: absolute;
    top: 15px;
    left: 244px;
    @include respond-to(desktop) {
      position: relative;
      top: 0px !important;
      left: 0px !important;
    }
  }
  .fc {
    height: 100%;
    .fc-button-group {
      background-color: #efefef;
      button {
        background-color: #efefef;
        color: #000;
      }
    }
    table {
      border-color: #dfdfdf;
      td,
      th {
        border-color: #dfdfdf;
        background-color: rgb(44, 62, 80);
        cursor: pointer;

        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}

.messageForm {
  &.leadForm {
    @include respond-to(desktop) {
      padding: 0;
    }
  }

  .fileWrapper {
    display: flex;
    align-items: center;
    height: auto;
    flex-direction: column;
    background: #198754;
    color: #fff;
    border-radius: 10px;
    width: 100%;
    .fileData {
      background-color: #198754;
      color: #fff;
      width: inherit;
      padding: 10px;
      svg {
        font-size: 30px;
        cursor: pointer;
      }
    }
    input {
      display: none;
    }

    .text-area {
      width: inherit;
      position: relative;
      textarea {
        border-radius: 0;
        resize: none;
        overflow: hidden;
      }
    }
  }

  .messageList {
    position: relative;
    padding: 10px 0;
    height: 200px;
    overflow-y: auto;
    margin: 10px 0;
    padding: 10px;
    background: #f6f3f3;
    .messageListItem {
      color: #000;
      margin-bottom: 5px;
      .messageListItemInner {
        background: #fff;
        width: auto;
        padding: 10px;
        border-top-right-radius: 15px;
        max-width: max-content;
      }
      &.right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .messageListItemInner {
          background-color: #dcf8c6;
          border-bottom-right-radius: 0 !important;
        }
      }
      .note {
        font-size: 11px;
        margin-top: 5px;
      }
      .msg {
        position: relative;
        display: flex;
        justify-content: space-between;
        .actions {
          padding: 0 10px;
          svg {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.p-list {
  position: relative;
  margin: 10px 0;
  .p-list-item {
    padding: 10px;
    background: #dfdfdf;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }
}
