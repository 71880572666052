// backend
.componentList {
  border: 1px solid #000;
  .componentListItem {
    border: 1px solid #000;
    margin: 10px;
    padding: 10px;
    .titleInfo {
      display: flex;
      border-bottom: 1px solid #000;
      justify-content: space-between;
    }
    .galleryList {
      position: relative;
      border: 1px solid #000;
      padding: 5px;
      .galleryListItem {
        border: 1px solid #000;
        padding: 10px;
        margin-bottom: 10px;
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #000;
        }
      }
    }
    .accordionList {
      position: relative;
      border: 1px solid #000;
      padding: 5px;
      .accordionListItem {
        border: 1px solid #000;
        padding: 10px;
        margin-bottom: 10px;
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #000;
        }
      }
    }
  }
}

// frontend
.front_component_list {
  position: relative;
  //max-width: 1100px;
  margin: 0 auto;
  //padding: 5px;
  //overflow-y: auto;
  margin-bottom: 150px;
  display: flex;
  flex-wrap: wrap;
  .front_component_list_item {
    position: relative;
    margin: 3px 0;
    width: 100%;
    &.content_right {
      text-align: right;
    }
    &.content_left {
      text-align: left;
    }
    &.content_center {
      text-align: center;
    }
    &.grid_size_half {
      width: 50%;
      @include respond-to(desktop) {
        width: 100%;
      }
    }

    &.header_component {
      font-size: 40px;
      line-height: 48px;
    }

    &.imagewithtext_component {
      &.image_right {
        .imagewithtext_component_inner {
          flex-direction: row-reverse;
          @include respond-to(desktop) {
            flex-direction: column;
          }
        }
      }
      .imagewithtext_component_inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        @include respond-to(desktop) {
          flex-direction: column;
        }
        img {
          width: 50%;
        }

        .innerInfo {
          padding: 20px;
          .title {
            font-size: 30px;
            line-height: 36px;
            display: block;
          }
          .des {
            font-size: 26px;
            line-height: 32px;
            font-weight: 300;
          }
        }
      }
    }
    .contactForm {
      display: flex;
      @include respond-to(desktop) {
        flex-direction: column;
      }
    }

    &.gallery_component {
      .g_list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .g_list_item {
          display: flex;
          align-items: center;
          padding: 10px;
          font-size: 18px;
          line-height: 26px;
          img {
            padding-right: 10px;
            max-width: 100px;
          }
        }
      }
      .exployeeList {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        //flex-wrap: wrap;
        overflow-x: auto;
        .exployeeListInner {
          padding-right: 10px;
          text-align: center;
          font-size: 13px;
          line-height: 13px;
          img {
            width: 100px;
            border-radius: 50%;
            height: 100px;
            min-height: 100px;
            min-width: 100px;
            border: 1px solid #dfdfdf;
          }
        }
      }
    }

    .g_list_light_box {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      @include respond-to(desktop) {
        flex-direction: column;
      }
      .g_list_light_box_item {
        margin: 10px;
        width: calc(25% - 20px);
        cursor: pointer;
        @include respond-to(desktop) {
          width: auto;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}

.dynamicPage {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .footer {
    height: auto !important;
    bottom: 0 !important;
  }
  .front_component_list,
  .infoWrapper {
    margin-top: 75px;
  }
}

.media_picker {
  position: relative;
  .media_picker_header {
    display: flex;
    border: 1px solid #787878;
    border-radius: 10px;
    align-items: center;
    padding: 10px;
    input {
      max-width: 300px;
      margin-right: 5px;
    }
    .upload {
      display: flex;
      align-items: center;
    }
  }
  .media_items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-height: 400px;
    overflow-y: auto;
    .media_item {
      position: relative;
      display: flex;
      flex-direction: column;
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      margin: 6px;
      width: calc(25% - 12px);
      height: auto;
      overflow-wrap: break-word;
      cursor: pointer;
      &.active {
        border: 1px solid green;
      }
      img {
        position: relative;
        object-fit: center;
        object-position: top;
        width: 100%;
        height: 100px;
        border-radius: 10px;
        padding: 5px;
      }
      p {
        font-size: 12px;
        line-break: auto;
        padding: 5px;
      }
    }
  }
}

.tickets_page {
  position: relative;
  h3 {
    padding-left: 20px;
  }
  .ticketList {
    position: relative;
    padding: 20px;
    //display: flex;
    //align-items: center;
    .ticketListItem {
      padding: 5px;
      //width: calc(25% - 5px);
      //margin: 5px;
      //height: 100px;
      background: #efefef;
      position: relative;
      margin-bottom: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
