.mobile_form_wrapper {
  position: relative;
  //background: #000;
  color: #fff;
  //height: calc(100vh - 75px);
  //padding: 10px;
  @include respond-to(desktop) {
    font-size: 15px;
  }

  .mobile_form_pre_box {
    height: 100px;
    position: relative;
    background: #000;
  }
  .mobile_form_wrapper_inner {
    position: absolute;
    //height: calc(100vh - 180px);
    height: auto;
    border-radius: 10px;
    width: calc(100% - 20px);
    text-transform: capitalize;
    background-color: #3d454b;
    margin: 10px;
    padding: 20px;
    top: 25px;
    .progressBar {
      .bg-success {
        background-color: #2c8a2c !important;
      }
    }
    .mobile_form_step {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      text-transform: capitalize;
      margin: 10px 0;
      p {
        margin-bottom: 0px;
      }
      .m-title {
        font-size: 13px;
      }

      .s-title {
        display: flex;
        padding: 2px;
      }
      input {
        border-radius: 0;
      }
      select {
        border-radius: 0;
      }
      .b-title {
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        display: flex;
        margin-bottom: 3px;
      }
      .home-types {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        @include respond-to(desktop) {
          flex-direction: column;
        }
        .home-type {
          padding: 5px 15px;
          background-color: #fff;
          color: #2c8a2c;
          width: calc(50% - 5px);
          margin-bottom: 5px;
          border: 1px solid lightgrey;
          cursor: pointer;
          display: flex;
          align-items: center;

          @include respond-to(desktop) {
            width: 100%;
          }
          &.active {
            background-color: #2c8a2c;
            color: #fff;
            border-color: #2c8a2c;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .home-roof-types {
        display: flex;
        align-items: center;
        &.disabled {
          .home-roof-type {
            background-color: lightgray;
            &.active {
              background-color: lightgray;
            }
          }
        }
        .home-roof-type {
          border: 1px solid lightgrey;
          padding: 10px 15px;
          margin-right: 5px;
          background-color: #fff;
          color: #2c8a2c;
          width: 25%;
          height: auto;
          cursor: pointer;
          text-align: center;
          @include respond-to(desktop) {
            flex-direction: column;
            margin: 2px;
            width: calc(25% - 4px);
          }

          img {
            width: 30px;
            height: 30px;
          }
          &.active {
            background-color: #2c8a2c;
            color: #fff;
            border-color: #2c8a2c;
          }
        }
      }
      .fvalues {
        display: flex;
        width: inherit;
        justify-content: space-between;
        gap: 5px;
        @include respond-to(desktop) {
          flex-wrap: wrap;
        }
        .fval {
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .energyInfo {
        position: relative;
        background: #d2b7bb;
        .energyInfoTop {
          padding: 10px;
        }
        .engeryInfoBottom {
          position: relative;
          display: flex;
          padding: 10px;
          justify-content: space-between;
          @include respond-to(desktop) {
            flex-direction: column;
          }
          .customSelect_grid {
            flex: 1;
            &:first-child {
              margin-right: 5px;
            }
          }
        }
      }
      .mobile_form_step_inner {
        text-transform: capitalize;
        position: relative;
        width: 100%;
        .form-label {
          margin-bottom: 0;
        }
        .b-title {
          font-size: 14px;
          line-height: 20px;
          text-transform: uppercase;
          display: flex;
          margin-bottom: 3px;
        }
        .checkBox {
          align-items: center;
        }
        input {
          margin: 0;
        }
        .auto_complete_inner {
          width: inherit;
          input {
            width: 100%;
          }
        }
      }
      .step5 {
        .customSelect_grid {
          margin-bottom: 5px;
        }
      }
    }
    .action_btns {
      //position: absolute;
      position: relative;
      width: 100%;
      bottom: 0;
      margin-top: 20px;
      //width: calc(100% - 40px);
      display: flex;
      justify-content: space-between;
      //bottom: 20px;
      align-items: center;
      gap: 5px;
      &.last {
        button {
          &:first-child {
            flex: 0;
            svg {
              font-size: 18px;
            }
          }
        }
      }
      button {
        display: flex;
        justify-content: space-between;
        bottom: 0;
        align-items: center;
        border-radius: 0;
        width: inherit;
        background-color: #2c8a2c;
        border-color: #2c8a2c;
      }
    }
  }
}

.mobileMap {
  position: relative;

  .mobileMap_mask {
    position: relative;
    height: inherit;
    background: rgba(0, 0, 0, 0.7);
    #customMap {
      height: calc(100vh - 75px);
    }
  }
  &.logged {
    #customMap {
      height: calc(100vh - 130px);
    }
  }
  .location_outer {
    position: absolute;
    padding: 0 20px;
    top: calc(50% - 110px);
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .b-title {
      font-size: 18px;
      font-weight: 300;
      span {
        font-weight: 700;
      }
    }
    .xs-title {
      font-size: 10px;
    }
  }
  &.noLoc {
    &.logged {
      height: calc(100vh - 130px);
    }
    height: calc(100vh - 75px);

    background-position: center;
    background-repeat: no-repeat;

    .location {
      &.small {
        top: calc(50% - 42px);
      }
    }
  }
  .location {
    &.small {
      width: calc(100% - 80px);
      top: 20px;
      left: 40px;
      .locationMask {
        width: 100%;
        .locationInner {
          width: inherit;
          .predictions {
            width: calc(100% + 12px);
            max-width: unset !important;
            min-width: unset !important;
          }
          .searchInfo {
            width: inherit;
            gap: 5px;
            input {
              width: inherit;
              max-width: unset;
            }
            svg {
              margin: 0;
            }
          }
        }
      }
    }
  }
  .rotationBtn {
    top: calc(50% - 70px);
    bottom: unset;
    right: 10px;
    height: 140px;
    left: unset;
  }
}

.actionsMenuList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  border: 0;
  bottom: 0;
  background: #000;
  color: #fff;
  width: 100%;
  .actionsMenuListItem {
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    font-size: 10px;
    flex: 1;
    cursor: pointer;
    text-transform: capitalize;
    svg {
      font-size: 15px;
      margin-bottom: 5px;
    }
    &.active {
      background: green;
    }
  }
}
