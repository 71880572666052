// buttons
.filters {
  position: relative;
  padding: 40px;
  @include respond-to(desktop) {
    padding: 10px;
  }

  button {
    margin: 0;
    padding: 5px 20px;
    background-color: #9abf0f;
    color: #fff;
    border: 0;
    font-size: 18px;
    &.btn-red {
      background-color: red;
    }
    &.btn-green {
      background-color: green;
    }
  }
}

.customTable {
  padding: 0 40px;
  position: relative;
  @include respond-to(desktop) {
    padding: 10px;
  }

  .head {
    th {
      background-color: #261d32;
      color: #fff;
    }
  }
  tr {
    border-bottom: 1px solid #dfdfdf;
    td {
      &:first-child {
        border-left: 1px solid #dfdfdf;
      }
      &:last-child {
        border-right: 1px solid #dfdfdf;
      }
      .c_btn {
        width: 20px;
        height: 20px;
        background: #dfdfdf;
        border-radius: 50%;
        position: relative;
        &.btn_orange_clr {
          background: orange;
        }
        &.btn_orange_green_clr {
          background-color: orange;
          &::before {
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: green;
            position: absolute;
            left: 10px;
          }
        }
        &.btn_green_clr {
          background: green;
        }
      }
    }
    &.even {
      td {
        background-color: #efefef;
      }
    }
  }

  .pagination {
    position: relative;
    display: flex;
    justify-content: space-between;
    button {
      border-right: 0;
      border: 1px solid #9abf0f;
      &:last-child {
        border-right: 1;
      }
    }
  }
}

.appointmentsList {
  label {
    text-transform: capitalize;
    font-weight: 800;
  }
  button {
    text-transform: capitalize;
    color: #000;
    font-weight: bold;
  }
  .blue {
    button {
      background-color: blue;
    }
  }
  .orange {
    button {
      background-color: orange;
    }
  }
  .green {
    button {
      background-color: green;
    }
  }

  .red {
    button {
      background-color: red;
    }
  }
}

.orderDetail {
  background: #dfdfdf;
  .orderDetailBody {
    background: #efefef;
    .productItems {
      position: relative;
      display: flex;
      flex-direction: column;
      margin: 10px;
      padding: 10px;
      .productItem {
        position: relative;
        background: #dfdfdf;
        margin-bottom: 10px;
        padding: 10px;
        .variation_items {
          position: relative;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .variation_item {
            position: relative;
            padding: 5px;
            margin-right: 10px;
            background-color: #261d32;
            color: #fff;
            text-transform: capitalize;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.cartInfoBody {
  .variation_items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .variation_item {
      padding: 5px 20px;
      background: #efefef;
      color: black;
      margin-right: 10px;
      margin-bottom: 5px;
      border-radius: 20px;
    }
  }
}

.rotate {
  transition: transform 0.5s ease; /* Smooth transition */
}

.loginModal {
  .side-image {
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
      height: inherit;
      width: inherit;
    }
  }
  .reset {
    text-align: right;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    .btn-full {
      width: 100%;
    }
    .googleLogin {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include respond-to(desktop) {
        margin-top: 20px;
      }
      > .nsm7Bb-HzV7m-LgbsSe {
        min-width: inherit !important;
        max-width: inherit !important;
        width: inherit !important;
      }
    }
  }
}

.customSelect_grid {
  display: flex;
  .box {
    width: 50px;
    height: inherit;
    background: #2c8a2c;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: #fff;
  }
}

.placeholder_center {
  &::placeholder {
    color: lightgray !important;
    text-align: center;
  }
}

.placeholder_light {
  &::placeholder {
    color: lightgray !important;
  }
}

.gm-style-iw-ch {
  display: none !important;
}
.gm-style .gm-style-iw-d {
  margin-top: 10px;
}

.notification {
  position: relative;
  .count {
    right: 12px;
    position: absolute;
    width: 15px;
    height: 15px;
    background: #ff0404;
    font-size: 10px;
    display: flex;
    top: -3px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 9px;
    @include respond-to(desktop) {
      left: 12px;
      right: none;
      top: 5px;
    }
  }
}

.CustomTableList {
  position: relative;
  //background-color: lightgray;
  height: auto;
  padding: 10px;
  margin: 20px 0;
  border: 1px solid #efefef;
  @include respond-to(desktop) {
    overflow-x: auto;
    background-color: #f2f2f2;
  }
  .top {
    align-items: center;
    margin: 10px 0;
    font-weight: bold;
    button {
      @include respond-to(desktop) {
        font-size: 10px;
        padding: 5px;
      }
    }
  }

  .middle {
    .legend {
      padding: 10px 0;
      font-weight: 500;
      gap: 3px;
      display: flex;
      @include respond-to(desktop) {
        justify-content: space-between;
      }
      .legendItem {
        min-width: 130px;
        width: 130px;
        text-align: center;
        @include respond-to(desktop) {
          width: auto !important;
          min-width: auto !important;
          flex: 1;
        }
        &:last-child {
          width: auto !important;
          min-width: auto !important;
          text-align: left;
        }
        &:first-child {
          @include respond-to(desktop) {
            flex: 0 !important;
            margin-right: 2px;
          }
        }
      }
    }
    .columns {
      position: relative;
      .column {
        position: relative;
        border-bottom: 1px solid lightgray;
        padding: 10px 0;
        font-weight: 300;
        gap: 3px;
        display: flex;
        @include respond-to(desktop) {
          justify-content: space-between;
        }
        .columnInnerItem {
          min-width: 130px;
          width: 130px;
          text-align: center;
          @include respond-to(desktop) {
            width: auto !important;
            min-width: auto !important;
            flex: 1;
          }
          &:last-child {
            width: auto !important;
            min-width: auto !important;
            text-align: left;
          }
          &.extra_link {
            text-decoration: underline;
            color: blue;
            text-transform: capitalize;
            cursor: pointer;
          }
          &:first-child {
            @include respond-to(desktop) {
              flex: 0 !important;
            }
          }
          svg {
            @include respond-to(desktop) {
              font-size: 12px;
              &:first-child {
                margin-left: 2px;
              }
              margin-right: 5px !important;
            }
          }
        }
      }
    }
  }

  .bottom {
    padding: 10px 0;
    .bottom_right {
      position: relative;
      .disabled {
        color: lightgrey;
      }
    }
    .bottom_left {
      .helpIcons {
        @include respond-to(desktop) {
          flex-direction: column;
          gap: 5px;
        }
      }
    }
  }
}

.langBtn {
  position: relative;
  @include respond-to(desktop) {
    display: none;
  }
}
.lang_list_menu {
  position: absolute;
  top: 55px;
  right: 5px;
  height: auto;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  background: #efefef;
  padding: 5px;
  @include respond-to(desktop) {
    width: 100%;
  }
  .lang_list_menu_item {
    cursor: pointer;
    display: flex;
    color: #000;
    align-items: center;
    margin-bottom: 5px;
    &.active {
      background-color: lightgrey;
    }
  }
}

.widgets_container {
  position: relative;
  @include respond-to(desktop) {
    margin-bottom: 80px;
  }
  .widget_item {
    max-height: 400px;
    position: relative;
    @include respond-to(desktop) {
      max-height: none;
      height: auto;
    }
  }
}

.calenderForm {
  position: relative;
  padding: 20px;
  height: calc(100vh - 30px);
  &.front {
    height: 650px;
    padding: 10px;
    .calender_widget {
      position: relative;
      background-color: #f2f2f2;
      padding: 10px;
      height: calc(100% - 20px);
      .customCalender {
        background: #fff;
        position: relative;
        padding: 5px;
        height: 550px;
        overflow: hidden;
      }
      h3 {
        font-size: 16px;
      }
      .fc {
        margin: 10px 0;
        height: 100%;
        overflow: hidden;
        .fc-today-button {
          display: none !important;
        }
        .fc-button-group {
          button {
            background-color: #f2f2f2;
            color: #000;
            border-color: inherit;
          }
        }
        table {
          border-color: #fff;
          td,
          th {
            border-color: #fff;
            background-color: #fff;
            cursor: pointer;
            a {
              color: #000;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.progress_levels_container {
  position: relative;
  height: auto;
  margin: 40px 0;
  text-transform: uppercase;
  h3 {
    font-size: 16px;
    padding-left: 10px;
  }
  .progress_levels {
    position: relative;
    background-color: #f2f2f2;
    padding: 10px;
    border: 1px solid #efefef;
    h3 {
      margin: 20px 0;
    }
    .progress_level {
      color: grey;
      margin: 10px 0;
      font-size: 14px;
      @include respond-to(desktop) {
        flex-wrap: wrap;
      }

      .progress_level_left {
        width: 30%;
      }
      .progress_level_right {
        width: 70%;
        .progress {
          border-radius: 0;
          margin-right: 10px;
          width: 95%;
          background-color: #e0e0e0;
          &.blue {
            .progress-bar {
              background-color: #008cd6;
            }
          }
          &.pink {
            .progress-bar {
              background-color: #ff73ff;
            }
          }
          &.black {
            .progress-bar {
              background-color: #000;
            }
          }
          &.orange {
            .progress-bar {
              background-color: #ff5c26;
            }
          }
          &.green {
            .progress-bar {
              background-color: #2db200;
            }
          }
          &.yellow {
            .progress-bar {
              background-color: #e8e800;
            }
          }
        }
        span {
          width: 5%;
        }
      }
    }
  }
}

.modal {
  z-index: 9999;
}

.notificationsList {
  position: fixed;
  width: 500px;
  background: #000;
  top: 80px;
  z-index: 9999;
  right: 5px;
  padding: 20px;
  color: #fff;
  .intro {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    svg {
      cursor: pointer;
    }
  }
  @include respond-to(desktop) {
    background-color: #000;
    top: 75px;
    left: 0;
    width: 100%;
    height: 100vh;
    max-height: none;
  }
  .notificationsListWrapper {
    position: relative;
    max-height: 400px;
    overflow-y: auto;
    padding: 0 5px;
  }

  .notificationsList_item {
    cursor: pointer;
    margin-bottom: 5px;
    position: relative;
    //border: 1px solid #efefef;
    background: #efefef;
    color: #000;
    .title {
      position: relative;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respond-to(desktop) {
        flex-direction: column;
      }
      &::before {
        content: "";
        width: 5px;
        background: red;
        position: absolute;
        display: flex;
        height: 100%;
        top: 0px;
        left: -1px;
      }
    }

    .des {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-items: center;
      gap: 4px;
      @include respond-to(desktop) {
        flex-direction: column;
      }
    }

    &.active {
      .title {
        &::before {
          background: #2c8a2c;
        }
      }
    }
  }
}

.swal2-container {
  z-index: 99999;
}

.Toastify__toast-container {
  z-index: 99999;
}

.quote_info_page {
  height: auto;
  min-height: 100vh;
  margin-bottom: 40px;
  .m_title {
    font-size: 25px;
    font-weight: bold;
    margin: 5px 0;
    text-transform: uppercase;
  }
  .s_title {
    font-weight: bold;
    margin: 5px 0;
    text-transform: uppercase;
  }
  .estimate_panel_info {
    position: relative;
    padding: 10px;

    .estimate_panel_info_house_outer {
      display: flex;
      @include respond-to(desktop) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .user_info_section {
      position: relative;
      .user_info_section_inner {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        align-items: center;
        background-color: #312e2e;
        color: #fff;
        border-radius: 10px;
        .address {
          @include respond-to(desktop) {
            margin-bottom: 10px;
          }
        }
        @include respond-to(desktop) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    .commonboxes {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin: 10px;
      .commonbox {
        background: #312e2e;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        width: calc(50% - 5px);
        color: #fff;
        @include respond-to(desktop) {
          flex-direction: column;
          width: calc(100% - 5px);
        }
        .commonbox_item {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          text-transform: uppercase;
          align-items: center;
          flex-wrap: wrap;
          .extraIcon {
            img {
              max-width: 30px;
            }
          }

          .commonbox_item_sub {
            display: flex;
            justify-content: flex-start;
            gap: 5px;
            align-items: center;
          }
        }
      }
    }
  }
}

.preferences_page {
  position: relative;
  margin: 20px;
  .lang_list_menu {
    position: relative;
    top: 0;
    width: auto;
    left: 0;
    right: 0;
    z-index: 0;
    .lang_list_menu_item {
      padding: 5px;
      gap: 5px;
    }
  }
}

// menu pages
.pagesListWrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px;
  width: 400px;
  right: 0;
  background: #000;
  z-index: 99999;
  padding: 10px;
  max-height: 400px;
  overflow-y: auto;
  @include respond-to(desktop) {
    width: 100%;
  }
  .pagesListItem {
    padding: 10px;
    cursor: pointer;
    background: #2c2c2c;
    margin: 5px 0;
    color: #fff;
  }
}

.referral_wrapper {
  position: relative;
  background: #f2f2f2;
  margin-top: 20px;
  margin-left: 10px;
  padding: 10px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @include respond-to(desktop) {
    margin-left: 0;
  }
  .referral_top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1;
  }
  .referral_wrapper_inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }
  .referral_bottom {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-size: 12px;
    .referral_bottom_list {
      display: flex;
      flex-direction: column;
    }
  }
}
