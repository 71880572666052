.e_prod_list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  @include respond-to(desktop) {
    margin-bottom: 80px;
  }
  .e_prod_list_item {
    width: calc(25% - 8px);
    height: 300px;
    margin: 4px;
    border-radius: 10px;
    @include respond-to(desktop) {
      width: 100%;
    }

    &.hide {
      display: none;
    }

    .quantity {
      position: absolute;
      top: -35px;
      right: 5px;
      width: auto;
      padding: 0 5px;
      max-width: 50px;
      text-align: center;
      border: none;
    }

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      object-position: top;
    }
    .category_list {
      position: absolute;
      display: flex;
      top: 10px;

      .category_list_item {
        padding: 5px 10px;
        border-radius: 10px;
        margin: 0 10px;
        background: #000;
        color: #fff;
      }
    }
    .subInfo {
      position: relative;
      padding: 5px;
      border: 1px solid #efefef;
      height: 100px;
      .title {
        font-weight: bold;
        height: 50px;
        overflow: hidden;
      }
      .priceInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .price {
          text-transform: capitalize;
          text-decoration: line-through;
        }
        .button {
          padding: 5px 20px;
          background: red;
          color: #fff;
          text-transform: uppercase;
        }
      }
    }
  }
}

.cartInfo {
  position: fixed;
  min-height: 100vh;
  background: #000;
  z-index: 9999;
  right: 0;
  color: #fff;
  width: 50%;
  overflow-y: auto;
  height: 100vh;

  @include respond-to(desktop) {
    width: 100%;
    min-height: 0;
    padding-bottom: 50px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
  }
  .cartInfoBody {
    height: calc(100% - 120px);
    overflow: auto;
    @include respond-to(mobile) {
      height: calc(100% - 150px);
    }
  }
  .cart-product-list {
    display: block;
    flex-direction: column;
    padding: 10px;
    .cart-product-list-item {
      padding: 10px;
      margin-bottom: 5px;
      border: 1px solid #333232;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .info {
        display: flex;
        flex-direction: column;
        width: 100%;
        .quantity,
        .price {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .cart-product-total {
    display: flex;
    justify-content: space-between;
    border: 1px solid #333232;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    .actionBtn {
      padding: 5px 10px;
      margin: 10px 0;
      background: green;
    }
  }
}
.closeBtn {
  position: absolute;
  top: calc(50% - 15px);
  right: 20%;
  z-index: 1;
  width: 50px;
  background: black;
  color: #fff;
  text-align: center;
  cursor: pointer;
  padding: 5px;
}
.cartBtn {
  position: absolute;
  right: 5px;
  width: 50px;
  background: #000;
  border-radius: 50%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 40px;
  z-index: 999;
  top: calc(50% - 25px);
  .count {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 900;
    border-radius: 50%;
    color: red;
    top: 0px;
    svg {
      color: #fff;
    }
  }
}

.catList {
  position: relative;
  display: flex;
  overflow-x: auto;
  margin: 10px 0;
  .catListItem {
    padding: 5px 30px;
    border: 1px solid grey;
    background-color: #fff;
    color: #000;
    margin: 5px;
    border-radius: 10px;
    cursor: pointer;
    &.active {
      background-color: #000;
      color: #fff;
    }
  }
}

.cartContainer,
.productDetail {
  .footer {
    &.noTop {
      height: 38px;
    }
  }
}

.productDetail {
  overflow-y: auto;
  margin-bottom: 70px;
  .productDetailWrapper {
    display: flex;
    margin: 10px 0;
    @include respond-to(desktop) {
      flex-direction: column;
    }
    .productDetailWrapper_left {
      width: 50%;
      @include respond-to(desktop) {
        width: 100%;
      }
    }
    .productDetailWrapper_right {
      width: 50%;
      @include respond-to(desktop) {
        width: 100%;
      }

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include respond-to(desktop) {
          flex-direction: column;
        }
      }
      .infoQuantity {
        display: flex;
        max-width: 200px;
      }
      .priceInfo {
        position: relative;
        .info {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .full_price {
            text-decoration: line-through;
            padding-right: 10px;
          }
          input {
            max-width: 50px;
          }
        }
      }
      .infoOptions {
        display: flex;
        flex-direction: column;
        .infoOption {
          margin-bottom: 5px;
        }
      }
    }
  }
  .block-list {
    position: relative;
    height: auto;
    .block-list-item {
      display: flex;
      text-align: left;
      margin: 10px;
      @include respond-to(desktop) {
        flex-direction: column;
        img,
        .des {
          width: 100% !important;
        }
      }

      &.bold {
        .des {
          font-weight: bold;
        }
      }
      &.left {
        .des {
          justify-content: left;
        }
      }

      &.right {
        .des {
          justify-content: right;
        }
      }

      &.center {
        .des {
          justify-content: center;
        }
      }

      &:nth-child(even) {
        flex-direction: row-reverse;
        text-align: right;
        @include respond-to(desktop) {
          flex-direction: column;
        }
      }
      &.info {
        text-align: center;
        .des {
          width: 100%;
        }
      }
      img {
        width: 50%;
        object-fit: cover;
        height: 300px;
        object-position: top;
        padding: 10px;
        border-radius: 50px;
      }
      .des {
        width: 50%;
        padding: 10px;
        display: flex;
        align-items: center;
      }
    }
  }
}
