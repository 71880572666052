.frontFormOuter {
  background: #2c8a2c;
  position: absolute;
  top: 75px;
  width: 400px;
  height: calc(100vh - 177px);
  z-index: 999;
  right: 0;
  color: #fff;
  @include respond-to(desktop) {
    width: 280px;
    height: calc(100vh - 110px);
  }
  &.hide {
    display: none;
  }
  .title {
    font-size: 16px;
    line-height: 26px;
    padding: 5px 12px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .gm-ui-hover-effect,
  .gm-style .gm-style-dragger,
  .gm-style .gm-style-iw-c {
    width: 20px !important;
    height: 20px !important;
    background-color: white;
    border: 2px solid #007bff; /* Optional: Add border for better visibility */
    border-radius: 50%;
  }

  .frontForm {
    background: #fff;
    color: #2c8a2c;
    border-left: 2px solid #2c8a2c;
    height: calc(100% - 34px);
    position: relative;
    color: #000;
    padding: 10px;
    .danger {
      font-size: 12px;
    }
    .frontFormInner {
      height: 100%;
      position: relative;
      .frontFormInnerInfo {
        position: relative;
        height: inherit;
        overflow: hidden;
        overflow-y: auto;
        .auto_complete_inner {
          position: relative;
          &.auto {
            img {
              position: absolute;
              width: 20px;
              height: 20px;
              top: 10px;
              left: 5px;
            }
            input {
              padding-left: 27px;
            }
            .predictions {
              position: relative;
              width: calc(100% - 4px);
              background: #fff;
              padding: 2px;
              height: 200px;
              overflow: auto;
              border: 1px solid #efefef;
              z-index: 1;
              .prediction {
                position: relative;
                padding: 5px;
                background: #ffff;
                color: #000;
                border: 1px solid #efefef;
                width: inherit;
                margin-bottom: 5px;
                cursor: pointer;
              }
            }
          }
          &.extraicon {
            .pwdIcon {
              position: absolute;
              width: 20px;
              height: 20px;
              top: 10px;
              right: 5px;
              left: unset;
            }
          }
          &.icon {
            svg {
              position: absolute;
              width: 20px;
              height: 20px;
              top: 10px;
              left: 5px;
            }
            input {
              padding-left: 27px;
            }
          }
        }

        form {
          height: inherit;
          .frontFormInnerInfoSteps {
            position: relative;
            overflow: hidden;
            overflow-y: auto;
            height: calc(100% - 80px);
            @include respond-to(desktop) {
              height: calc(100% - 120px);
            }
            .b-title {
              font-weight: bold;
            }
            .checkBox {
              align-items: center;
              label {
                margin-bottom: 0;
              }
            }
            label {
              text-transform: uppercase;
              font-size: 12px;
              line-height: 12px;
              margin-bottom: 5px;
              &.step2 {
                margin-bottom: 0px;
              }
            }
            .polygon_field {
              position: relative;
              width: 100%;
              height: 100%;
              margin: 5px 0;

              img {
                width: 100%;
                object-fit: contain;
              }
            }

            p {
              font-size: 14px;
              margin: 0px;
              margin-bottom: 5px;
            }
            .home-types {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              @include respond-to(desktop) {
                flex-direction: column;
              }
              .home-type {
                padding: 5px 15px;
                background-color: #fff;
                color: #2c8a2c;
                width: calc(50% - 8px);
                margin-right: 8px;
                margin-bottom: 8px;
                border: 1px solid lightgrey;
                cursor: pointer;
                display: flex;
                align-items: center;
                svg {
                  margin-right: 10px;
                }
                @include respond-to(desktop) {
                  width: 100%;
                }
                &.active {
                  background-color: #2c8a2c;
                  color: #fff;
                }
              }
            }
            .home-roof-types {
              display: flex;
              align-items: center;

              &.disabled {
                .home-roof-type {
                  background-color: lightgray;
                  &.active {
                    background-color: lightgray;
                  }
                }
              }
              .home-roof-type {
                border: 1px solid lightgrey;
                padding: 10px 15px;
                margin-right: 5px;
                background-color: #fff;
                color: #2c8a2c;
                width: 25%;
                height: auto;
                cursor: pointer;
                text-align: center;
                @include respond-to(desktop) {
                  flex-direction: column;
                  margin: 2px;
                  width: calc(25% - 4px);
                }

                img {
                  width: 30px;
                  height: 30px;
                }
                &.active {
                  background-color: #2c8a2c;
                  color: #fff;
                }
              }
            }
            .energyInfo {
              position: relative;
              background: #d2b7bb;
              .energyInfoTop {
                padding: 10px;
              }
              .engeryInfoBottom {
                position: relative;
                display: flex;
                padding: 10px;
                justify-content: space-between;
                @include respond-to(desktop) {
                  flex-direction: column;
                }
                .customSelect_grid {
                  flex: 1;
                  &:first-child {
                    margin-right: 5px;
                  }
                }
              }
            }
            .meters {
              display: flex;
              justify-content: space-between;
              width: 100%;
              flex-wrap: wrap;
              .meter {
                flex: 1;
                width: inherit;
                @include respond-to(desktop) {
                  flex: auto;
                }
                &.mqt {
                  margin-right: 5px;
                  @include respond-to(desktop) {
                    margin-right: 0;
                  }
                }
              }
            }
            .fvalues {
              display: flex;
              justify-content: space-between;
              gap: 5px;
              @include respond-to(desktop) {
                flex-wrap: wrap;
              }
              .fval {
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  select {
    border-radius: 0;
  }
  .btns {
    position: absolute;
    bottom: 0px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 8px;
    z-index: 999;
    background: lightgrey;

    button {
      cursor: pointer;
      width: inherit;
      border-radius: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.last {
        width: 15% !important;
        display: block !important;
        text-align: center;
      }
      &.btn-success {
        background-color: #2c8a2c !important;
        text-align: center;
        display: block;
      }
    }
  }
}

.profile_page {
  position: relative;
  .infoWrapper {
    height: calc(100vh - 170px);
    overflow-y: auto;
    .profileForm {
      position: relative;
      height: auto;

      .home-types {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
      }
      .leadFormInner {
        background-color: #f2f2f2;
        padding: 10px;
      }
    }
    .updatePasswordForm {
      position: relative;
      height: auto;
      max-height: 350px;
      .leadForm {
        .leadFormInner {
          background-color: #f2f2f2;
          padding: 10px;
        }
      }
    }
    .leadForm {
      padding: 20px 10px;
      @include respond-to(desktop) {
        padding: 20px 0px;
      }
    }
  }

  .home-types {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    @include respond-to(desktop) {
      flex-direction: column;
    }
    .home-type {
      padding: 5px 15px;
      background-color: #fff;
      color: #2c8a2c;
      width: calc(50% - 4px);

      margin-bottom: 8px;
      border: 1px solid lightgrey;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:first-child {
        margin-right: 8px;
      }
      svg {
        margin-right: 10px;
      }
      @include respond-to(desktop) {
        width: 100%;
      }
      &.active {
        background-color: #2c8a2c;
        color: #fff;
      }
    }
  }
  .auto_complete_inner {
    position: relative;
    &.icon {
      svg {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 10px;
        left: 5px;
        @include respond-to(desktop) {
          top: 5px;
        }
      }
      input {
        padding-left: 27px;
      }
    }
    &.extraicon {
      .pwdIcon {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 10px;
        right: 5px;
        left: unset;
        @include respond-to(desktop) {
          top: 5px;
        }
      }
    }
    &.auto {
      img {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 10px;
        left: 5px;
        @include respond-to(desktop) {
          top: 5px;
        }
      }
      input {
        padding-left: 27px;
      }
      .predictions {
        position: relative;
        width: calc(100% - 4px);
        background: #fff;
        padding: 2px;
        height: 200px;
        overflow: auto;
        border: 1px solid #efefef;
        z-index: 1;
        .prediction {
          position: relative;
          padding: 5px;
          background: #ffff;
          color: #000;
          border: 1px solid #efefef;
          width: inherit;
          margin-bottom: 5px;
          cursor: pointer;
        }
      }
    }
  }
}

.auto_complete_inner {
  position: relative;
  &.icon {
    svg {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 10px;
      left: 5px;
      @include respond-to(desktop) {
        top: 5px;
      }
    }
    input {
      padding-left: 27px;
    }
  }
  &.extraicon {
    .pwdIcon {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 10px;
      right: 5px;
      left: unset;
      @include respond-to(desktop) {
        top: 5px;
      }
    }
  }
  &.auto {
    img {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 10px;
      left: 5px;
      @include respond-to(desktop) {
        top: 5px;
      }
    }
    input {
      padding-left: 27px;
    }
    .predictions {
      position: relative;
      width: calc(100% - 4px);
      background: #fff;
      padding: 2px;
      height: 200px;
      overflow: auto;
      border: 1px solid #efefef;
      z-index: 1;
      .prediction {
        position: relative;
        padding: 5px;
        background: #ffff;
        color: #000;
        border: 1px solid #efefef;
        width: inherit;
        margin-bottom: 5px;
        cursor: pointer;
      }
    }
  }
}
