@mixin respond-to($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 767px) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }
}
